const App = () => {
  return (
    <>
      <div className="pusher">
        <div
          className="ui inverted vertical masthead center aligned segment"
          style={{ backgroundColor: "#7D4F50" }}
        >
          <div className="ui two column stackable grid container">
            <h1
              className="ui column header inverted huge center middle aligned"
              style={{ color: "#dadfdc", fontSize: "400%" }}
            >
              DevEmo
              <br />
              <div
              style={{ fontSize: "70%", lineHeight: "1em"}}
            >
              software developers' 
              facial expression datasetset
              </div>
            </h1>
            <div className="ui column">
              <img
                className="ui large image centered bottom aligned"
                src="assets/ase_logo.png"
                alt="ASE logo"
              />
            </div>
          </div>
        </div>
        <div className="ui fluid container">
          <img
            className="ui image centered"
            src="assets/bottom.png"
            alt="bottom border"
          />
        </div>

        <div className="ui basic segment">
          <div className="ui equal width grid ">
            <div className="row">
              <div
                className="ui column"
                style={{ backgroundColor: "#D1BE9C", color: "#333333" }}
              >
                <div className="ui basic segment justified" style={{ maxWidth: "500px", float: "right" }}>
                  <h3
                    className="ui header large centered"
                    style={{ color: "#333333" }}
                  >
                    What is the DevEmo dataset?
                  </h3>
                  <p>
                  
                  The DevEmo dataset consists of 217 video clips showing students' facial expressions as they solve programming tasks. 
                  The recordings were collected in the participants' actual work environment. 
                  The video clips are annotated with five labels, including four emotions (anger, confusion, happiness and surprise) and a neutral state.                   
                  </p>
                </div>
              </div>
              <div
                className="ui column"
                style={{ backgroundColor: "#CC8B86", color: "#333333" }}
              >
                <div className="ui basic segment justified" style={{ maxWidth: "500px" }}>
                  <h3
                    className="ui header large centered"
                    style={{ color: "#333333" }}
                  >
                    What can it be used for?
                  </h3>
                  <p>
                    The DevEmo dataset can be used as a learning and test set to train deep learning models to recognize 
                    the emotions of computer users. Such models could find wide applications, including the development of 
                    more personalized and effective tools for computer-based learning environments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ui justified container padded segment"
          style={{ backgroundColor: "#F9EAE1" }}
        >
        <h3 className="ui header">How to get DevEmo dataset?</h3>

         <p>The dataset is distributed in the form of a zip file, which contains video files with fragments marked with emotion or neutral state. 
         In addition, a csv file is included that contains more detailed information about the tagged files, such as the number of annotators, 
         who labeled the fragment, or the video file codec used.</p>
         <p>To gain access to the dataset, send an email with the subject "DevEmo dataset access request" to <a href={"mailto:michal.wrobel@pg.edu.pl?subject=DevEmo dataset access request"}>michal.wrobel@pg.edu.pl</a>.</p>
         <p>The dataset is intended for the scientific community, therefore, please send emails from the addresses of your academic institutions. Otherwise, please describe in the body of the message the purpose for which the dataset will be used. </p>
         <p>The DevEmo dataset was developed as part of research conducted by the <a href={"https://affectivese.org"}> Affective Software Engineering</a> group.</p>

         <h3 className="ui header">More information</h3>
         <p>More detailed information on the DevEmo dataset can be found in our paper <a href={"https://www.mdpi.com/2076-3417/13/6/3839"}> "DevEmo — Software Developers' Facial Expression Dataset"</a>. 
            It's published under an open access Creative Common CC BY license. You can cite it as:</p>
            <div className="ui list">
              <PaperFull
                title="DevEmo—Software Developers’ Facial Expression Dataset"
                author="Manikowska M., Sadowski D., Sowinski A., Wrobel MR"
                booktitle="Applied Sciences"
                year="2023"
                url="https://www.mdpi.com/2076-3417/13/6/3839"
                doi="10.3390/app13063839"
              />
            </div>
        </div>

        <div
          className="ui text container segment"
          style={{ backgroundColor: "#AA998F" }}
        >
          <h3 className="ui header">Sample frames from DevEmo dataset clips</h3>
          <img alt="Sample clips from DevEmo dataset" style={{width: "100%"}} src={"assets/clips.png"} />

        </div>

        <div className="ui vertical footer segment">
          <div className="ui container">
            <div className="ui stackable equal height stackable grid">
              <div className="six wide column">
                <a href="https://www.pg.edu.pl/" alt="GDANSK Tech">
                  <img
                    className="ui tiny right floated image"
                    src="assets/logo_pg.png"
                    alt="GDANSK Tech"
                  />
                </a>
              </div>
              <div className="six wide column">
                <a href="https://www.eti.pg.edu.pl/" alt="ETI">
                  <img
                    className="ui tiny right floated image"
                    src="assets/logo.eti.png"
                    alt="WETI"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PaperFull = ({ title, author, booktitle, year, url, doi }) => {

  return (
    <>
      <div className="item">
        <i className="file alternate icon"></i>
        <div className="content">
          {author}, <a href={url} style={{ color: "navy" }}><cite>{title}</cite></a>, {booktitle}, {year}, doi:<a href={'https://doi.org/'+doi}>{doi}</a>
        </div>
      </div>
    </>
  );
}

export default App;
